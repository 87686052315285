"use strict";

class ScrollCatcher {
	constructor(threshold, callback) {
		const catcher = document.createElement("div");
		catcher.style.position = "absolute";
		catcher.style.width = "1px";
		catcher.style.height = `${threshold}px`;
		catcher.style.top = "0";
		catcher.style.left = "0";
		catcher.style.zIndex = "-1";
		catcher.style.opacity = "0";
		catcher.style.pointerEvents = "none";
		catcher.style.transform = "translate3d(0, 0, -1px)";

		document.body.append(catcher);

		const observer = new IntersectionObserver((entries) => {
			callback(!entries[0].intersectionRatio > 0);
		});

		observer.observe(catcher);
	}
}
